#hero {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  font-size: 2em;
  /*

  *********************** COLOR SCHEMES ***********************

   */
  /*

  *********************** MEDIA QUERIES ***********************

  */
}
#hero .hero__image {
  grid-column: page-body;
  grid-row: page-title/page-body;
  display: block;
  max-width: 75rem;
  width: min(100vw, 75rem);
  height: calc(100vh - 1.75rem);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-origin: padding-box;
  background-attachment: fixed;
}
#hero .hero__text .hero__text--header {
  line-height: 1em;
}
#hero .page__title {
  padding-top: 0.75em;
  background: none;
}
#hero.page:before, #hero.page:after {
  background: none;
}
@media screen and (prefers-color-scheme: light), screen and (prefers-color-scheme: no-preference) {
  #hero .hero__text * {
    color: var(--color-title-light);
  }
}
@media screen and (prefers-color-scheme: dark) {
  #hero .hero__text * {
    color: var(--color-title-dark);
  }
}
@media screen and (max-width: 600px) {
  #hero .hero__image {
    background-attachment: scroll;
    background-position: center bottom;
  }
}
@media screen and (max-width: 1360px) {
  #hero {
    font-size: calc(1vw + 1.1em);
  }
  #hero .hero__image {
    background-size: cover;
  }
}
@media screen and (min-width: 1361px) {
  #hero .hero__image {
    background-attachment: fixed;
    background-position: center;
  }
}

/*

  *********************** PRINT ***********************

   */
@media print {
  #home .page#hero .page__title .hero__text {
    position: relative;
    top: 0;
    left: 0;
  }
  #home .page#hero .page__title .hero__text * {
    text-align: center;
  }
  #home .page#hero .page__body.hero__image {
    max-height: 100vh !important;
    background: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/*# sourceMappingURL=hero.css.map */
