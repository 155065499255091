#resume {
  a {
    font-weight: 400;
  }

  span {
    display: inline-block;
  }

  ul,
  ol {
    list-style-type: square;
    list-style-position: inside;

    li {
      padding-top: 0;
      margin-left: 1em;

      &:before {
        content: "";
      }
    }
  }

  .resume__contact {
    .resume__contact--body {
      address div[class*="--"] {
        &:not(:first-of-type) {
          padding-top: 0.5em;
        }

        h4 {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        svg {
          // fill => background color
          // stroke => border color
          // stroke-width => border thickness
          padding-right: 0.5em;
          height: 0.75em;
        }

        &:hover,
        &:active {
          scale: 1.05;
          transition: scale 0.1s linear;

          svg {
            transform-origin: bottom;
            animation: squish-icon 0.75s infinite linear;
          }

          @keyframes squish-icon {
            0% {
              transform: scaleY(1);
            }
            30% {
              transform: scaleY(0.5);
            }
            70% {
              transform: scaleY(1.25);
            }
            100% {
              transform: scaleY(1);
            }
          }
        }
      }
    }
  }

  a {
    &.page__title {
      display: block;
    }
    &:has(.resume__block--heading) {
      transform: none;
    }
  }

  .resume__block--heading {
    transform: none;

    &:hover {
      transform: none;
    }

    section {
      margin: 0;
    }
  }

  .resume__block--bodies-of-text {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "resume-left resume-right";
    grid-column-gap: 2em;

    section {
      header {
        position: relative;
        margin-bottom: 1em;

        &:after {
          content: "";
          position: absolute;
          bottom: 0.25em;
          width: 100%;
          height: 1px;
        }
      }
    }

    .resume__block--small-bodies-of-text {
      grid-area: resume-right;
      overflow-wrap: anywhere;
      padding-top: 0;
    }

    .resume__block--large-bodies-of-text {
      grid-area: resume-left;
      padding-top: 0;
    }
  }

  .resume__skills {
    margin-top: 3em;

    .resume__skills--header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .resume__skills--body {
      .resume__skills--disclaimer {
        display: inline-block;
        width: 100%;
        text-align: center;
        font-size: 0.8em;
        position: relative;
        top: -1.5em;

        a {
          font-weight: 600;
        }
      }

      .resume__skills--skill {
        &:not(:first-of-type):not(:last-of-type) {
          margin: 0.5em 0;
        }

        .progress-bar__container {
          position: relative;
          display: block;
          height: 1.8em;
          border: 0.25em solid;
          border-radius: 0.8em;
          width: 100%;
          overflow: hidden;

          .progress-bar {
            position: relative;
            height: 1.3em;
            border-radius: 0.65em;
          }

          .skill-level__text {
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            top: 0;
            left: 0.5em;
            width: calc(100% - 1em);

            .skill-level__text--description {
              line-height: 1em !important;
            }

            .skill-level__text--value {
              line-height: 1em !important;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .card {
    padding: 0.25em 0.5em;
    transition: all 0.25s ease-in-out;

    &:first-of-type {
      margin-top: -0.25em;
    }

    &:not(+ .card) {
      margin-bottom: -0.25em;
    }

    .card__main-content {
      position: relative;
      transition: all 0.75s ease-in-out;
      padding-bottom: 0;
    }

    .card__collapsing-content {
      position: relative;
      transform-origin: top;
      transition: all 0.75s ease-in-out;
      padding-top: 0;
      transform: scaleY(0);
      height: 0;
    }

    & > .card__collapsing-content {
      &:before {
        height: 0;
      }
    }

    &.card__expandable {
      border-radius: 0.25em;

      .card__title {
        padding-right: 1em;
      }

      &:hover,
      &:active {
        cursor: pointer;
      }

      &.collapsed {
        & > .card__main-content {
          &:before {
            content: "+";
            position: absolute;
            box-sizing: content-box;
            font-size: 1.5em;
            width: 1em;
            height: 1em;
            font-weight: 600;
            padding: 0;
            line-height: 1em;
            top: -0.1em;
            right: -0.4em;
            color: transparent;
            background-clip: text;
            animation: expanding-card-pulse 3s infinite ease-in-out;
          }
        }

        & > .card__collapsing-content {
          transform: scaleY(0);
          height: 0;
        }
      }

      &:not(.collapsed) {
        margin-bottom: 0.5em;

        & > .card__main-content {
          padding-bottom: 0.6em;
        }

        & > .card__collapsing-content {
          padding-top: 0.6em;
          transform: scaleY(1);
          height: 100%;

          &:before {
            content: "";
            position: absolute;
            top: -0.1em;
            left: 12.5%;
            width: 75%;
            height: 0.2em;
            border: 0 solid transparent;
            border-radius: 50%;
          }

          .card:not(.collapsed) > .card__collapsing-content:before {
            height: 0.1em;
            top: 0;
          }
        }
      }
    }

    // To keep from propagating from parent card
    &:hover,
    &:active {
      cursor: auto;
    }

    .card__technologies {
      font-style: italic;

      &:not(.resume__education--isu--technologies) {
        font-weight: 500;
      }
    }

    .card {
      .card__title {
        font-weight: 525;
      }

      .card__expanded--element-list-heading,
      .card__expanded--bullet-point-heading {
        font-weight: 475;
      }

      .card__expanded--title,
      .card__expanded--description.resume__education--course:first-of-type {
        font-weight: 500;
      }
    }

    .card__title {
      &.resume__education--course--title {
        .course__id {
          width: 5em;
        }

        .course__title {
          padding-left: 1em;
        }
      }
    }

    .card__description {
      margin-left: 1em;
      display: block;
      padding-bottom: 0;
    }

    .card__expanded--description {
      &.resume__education--course {
        padding-bottom: 0;

        &:not(:first-of-type) {
          margin-left: 1em;
        }
      }

      & + .card__expanded--bullet-point-heading {
        padding-top: 0.25em;
      }
    }

    .card__expanded--title {
      padding-top: 0;
      padding-bottom: 0.25em;
    }

    .card__expanded--element-list-heading {
      padding-top: 0.25em;
      padding-bottom: 0.25em;
    }

    .card__expanded--element-list {
      padding: 0;
    }
  }

  /*

  *********************** COLOR SCHEMES ***********************

  */

  @media screen and (prefers-color-scheme: light),
    screen and (prefers-color-scheme: no-preference) {
    *:not(h3, h5, h6) {
      color: black;
    }

    .page__title {
      &:link,
      &:visited {
        text-decoration: none;
        color: var(--color-title-light);
      }
    }

    h1,
    h2 {
      color: var(--color-title-light);
    }

    .resume__block--bodies-of-text {
      section {
        header {
          &:after {
            background-color: var(--color-heading-light);
          }
        }
      }
    }

    .card {
      .card__technologies:not(.resume__education--isu--technologies) {
        color: var(--color-heading-light);
      }

      &.card__expandable {
        &:hover,
        &:active {
          &.collapsed {
            box-shadow: 0 0 1em 0.2em var(--color-background-title-light);
          }
        }

        .card__collapsing-content:before {
          background-color: var(--color-background-title-light);
        }

        &:not(.collapsed) {
          box-shadow: 0.2em 0.2em 0.5em -0.1em var(--color-background-title-light);
        }

        .card {
          &.card__expandable {
            &:not(.collapsed) {
              box-shadow: 0.2em 0.2em 0.5em 0.15em
                var(--color-background-title-light);
            }
          }
        }
      }

      @keyframes expanding-card-pulse {
        0% {
          background-color: rgba(var(--secondary-color-rgb), 0.1);
        }
        50% {
          background-color: rgba(var(--secondary-color-dark-rgb), 1);
        }
        100% {
          background-color: rgba(var(--secondary-color-rgb), 0.1);
        }
      }
    }

    .resume__contact {
      svg {
        fill: var(--color-link-light);
      }
    }

    .resume__skills--body {
      .resume__skills--disclaimer {
        a {
          &:link,
          &:visited {
            &:hover {
              color: var(--color-link-hover-light);
            }
          }
        }
      }
    }

    .progress-bar__container {
      background-color: var(--color-background-accent-light);
      border-color: var(--color-background-accent-light) !important;

      .progress-bar {
        background-color: var(--color-foreground-accent-light);
      }

      .skill-level__text {
        .skill-level__text--description,
        .skill-level__text--value {
          color: var(--color-text-accent-light);
        }
      }
    }
  }

  @media screen and (prefers-color-scheme: dark) {
    *:not(h3, h5, h6) {
      color: var(--secondary-color-dark);
    }

    h1,
    h2 {
      color: var(--color-title-dark);
    }

    .page__title {
      &:link,
      &:visited {
        text-decoration: none;
        color: var(--color-title-dark);
      }
    }

    .resume__block--bodies-of-text {
      section {
        header {
          &:after {
            background-color: var(--color-heading-dark);
          }
        }
      }
    }

    .card {
      .card__technologies:not(.resume__education--isu--technologies) {
        color: var(--color-heading-dark);
      }

      &.card__expandable {
        &:hover,
        &:active {
          &.collapsed {
            box-shadow: 0 0 1em 0.2em var(--color-foreground-accent-dark);
          }
        }

        .card__collapsing-content:before {
          background-color: var(--color-foreground-accent-dark);
        }

        &:not(.collapsed) {
          box-shadow: 0.2em 0.2em 0.5em -0.1em var(--color-foreground-accent-dark);
        }

        .card {
          &.card__expandable {
            &:not(.collapsed) {
              box-shadow: 0.2em 0.2em 0.5em 0.15em
                var(--color-foreground-accent-dark);
            }
          }
        }
      }

      @keyframes expanding-card-pulse {
        0% {
          background-color: rgba(var(--secondary-color-rgb), 0.1);
        }
        50% {
          background-color: rgba(var(--secondary-color-lighter-rgb), 1);
        }
        100% {
          background-color: rgba(var(--secondary-color-rgb), 0.1);
        }
      }
    }

    .resume__contact {
      svg {
        fill: var(--color-link-dark);
      }
    }

    .resume__skills--body {
      .resume__skills--disclaimer {
        a {
          &:link,
          &:visited {
            &:hover {
              color: var(--color-link-hover-dark);
            }
          }
        }
      }
    }

    .progress-bar__container {
      background-color: var(--color-background-accent-dark);
      border-color: var(--color-background-accent-dark) !important;

      .progress-bar {
        background-color: var(--color-foreground-accent-dark);
      }

      .skill-level__text {
        .skill-level__text--description,
        .skill-level__text--value {
          color: var(--color-text-accent-dark);
        }
      }
    }
  }

  /*

*********************** PRINT ***********************

*/

  @media print {
    padding-bottom: 0;
    height: 100%;
    background-color: white;
    font-size: 0.8rem;

    .resume__skills--skill.software,
    .resume__education--completed-courses,
    .card__collapsing-content,
    .expand-card-icon,
    .card.card__expandable.collapsed .card__main-content:before,
    .tooltip__icon,
    .resume__profile header,
    svg {
      /* Don't display the less relevant stuff when printing resume */
      display: none !important;
    }

    * {
      line-height: 1em !important;
    }

    *:not(h3, h5, h6) {
      color: black;
    }

    h1,
    h2 {
      color: var(--color-title-light);
    }

    h3,
    h5,
    h6 {
      color: var(--color-heading-light);
    }

    .page__title {
      position: relative;
      overflow: hidden;
      background: none;
      z-index: 0;
      font-size: 1.2em;

      &:link,
      &:visited {
        text-decoration: none;
        color: var(--color-title-light);
      }

      /* Enforcing background colors when printing */
      &:after {
        content: "";
        position: absolute;
        height: 0;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        border-bottom: 10em solid var(--color-background-title-light) !important;
      }
    }

    .page__body {
      padding: 0;
    }

    section:not(.resume__header) {
      ///padding-left: 1em !important;
      ///padding-right: 1em !important;
      ///margin: 0.5em 0;
      margin: 0; ///
      padding: 0.25em 1em; ///

      &:first-of-type {
        padding-top: 0.5em;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      header {
        ///margin-bottom: 0.5em;
        padding-bottom: 0.5em;
      }

      .card__technologies:not(.resume__education--isu--technologies) {
        color: var(--color-heading-light) !important;
      }
    }

    .resume__contact {
      .resume__contact--body address div[class*="--"]:not(:first-of-type) {
        padding-top: 0.5em;
      }
    }

    .card {
      box-shadow: none !important;
      margin-bottom: 0 !important;
      padding-top: 0.125em;
      padding-bottom: 0.125em;

      &.card__expandable {
        &.collapsed {
          .card__main-content {
            &:before {
              content: "";
            }
          }
        }

        &:not(.collapsed) {
          .card__main-content {
            padding-bottom: 0;
          }
        }
      }
    }

    .resume__block--bodies-of-text {
      ///grid-column-gap: 0.25em;
      grid-column-gap: 0;

      > * section:last-of-type {
        ///margin-bottom: 0;
      }

      .resume__block--small-bodies-of-text {
        position: relative;
        overflow: hidden;
        background: none;
        z-index: 0;
        /*display: flex;
        flex-direction: column;
        justify-content: space-between;*/

        /* Enforcing background colors when printing */
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          z-index: -1;
          border-right: 25em solid rgba(var(--secondary-color-light-rgb), 0.2);
        }
      }

      section {
        header {
          position: relative;
          padding-bottom: 0.25em; ///
          margin: 0; ///

          /* Enforcing background colors when printing */
          &:after {
            position: absolute;
            ///bottom: 0.125em; //0.7em;
            bottom: 0.45em; ///
            left: 0;
            border-bottom: 1px solid var(--color-heading-light) !important;
            background: none;
          }
        }
      }
    }

    section.resume__skills {
      margin-top: 2em;
      margin-bottom: 0;

      .resume__skills--body
        .resume__skills--skill:not(:first-of-type):not(:last-of-type) {
        ///margin: 0.5em 0;
        margin: 0;
        padding: 0.25em 0;
      }

      .resume__skills--body {
        padding-bottom: 1em;

        .resume__skills--disclaimer {
          ///top: -0.75em;
          top: -0.5em;

          a {
            font-size: 1em;
          }
        }

        .resume__skills--skill {
          .progress-bar__container {
            overflow: hidden;
            border: 0 solid transparent;
            background: none;

            /* Enforcing background colors when printing */
            &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              border: 20em solid var(--color-background-accent-light);
            }

            .progress-bar {
              left: 0.25em !important;
              top: 0.25em;
              background: none;
              overflow: hidden;

              /* Enforcing background colors when printing */
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                border: 20em solid var(--color-foreground-accent-light);
              }
            }

            /* Enforcing background colors when printing */
            .skill-level__text {
              left: 0.6em;
              background: none;
              align-items: center;

              .skill-level__text--description,
              .skill-level__text--value {
                color: black;
              }
            }
          }
        }
      }
    }
  }

  /*

*********************** MEDIA QUERIES ***********************

*/

  @media screen and (max-width: 800px) {
    .resume__block--bodies-of-text {
      .resume__block--small-bodies-of-text {
        .resume__skills {
          .skill-level__text {
            > * {
              font-size: 0.875em;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .resume__block--bodies-of-text {
      .resume__block--small-bodies-of-text {
        .resume__skills {
          .skill-level__text {
            > * {
              font-size: 0.75em;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .resume__block--bodies-of-text {
      display: flex;
      flex-direction: column;

      section {
        margin-bottom: 2em;
      }

      .resume__block--small-bodies-of-text {
        display: flex;
        flex-direction: column;

        .resume__contact {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .resume__contact--body address {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            column-gap: 1em;

            > div[class*="--"]:not(:last-child) {
              padding-top: 0;
              padding-bottom: 0.25rem;
            }

            > * {
              align-self: flex-start;
            }
          }
        }

        .resume__skills {
          margin: 0 0 1em 0;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          .resume__skills--header {
            margin: 0;
          }

          .resume__skills--disclaimer {
            top: -0.5em;
          }

          .skill-level__text {
            > * {
              font-size: 1em;
            }
          }
        }
      }

      .resume__block--large-bodies-of-text {
        .resume__profile {
          //margin-top: 0;
        }
        .resume__experience {
          margin-bottom: 0;
        }
      }
    }
  }
}
