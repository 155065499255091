#resume {
  /*

  *********************** COLOR SCHEMES ***********************

  */
  /*

  *********************** PRINT ***********************

  */
  /*

  *********************** MEDIA QUERIES ***********************

  */
}
#resume a {
  font-weight: 400;
}
#resume span {
  display: inline-block;
}
#resume ul,
#resume ol {
  list-style-type: square;
  list-style-position: inside;
}
#resume ul li,
#resume ol li {
  padding-top: 0;
  margin-left: 1em;
}
#resume ul li:before,
#resume ol li:before {
  content: "";
}
#resume .resume__contact .resume__contact--body address div[class*="--"]:not(:first-of-type) {
  padding-top: 0.5em;
}
#resume .resume__contact .resume__contact--body address div[class*="--"] h4 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#resume .resume__contact .resume__contact--body address div[class*="--"] svg {
  padding-right: 0.5em;
  height: 0.75em;
}
#resume .resume__contact .resume__contact--body address div[class*="--"]:hover, #resume .resume__contact .resume__contact--body address div[class*="--"]:active {
  scale: 1.05;
  transition: scale 0.1s linear;
}
#resume .resume__contact .resume__contact--body address div[class*="--"]:hover svg, #resume .resume__contact .resume__contact--body address div[class*="--"]:active svg {
  transform-origin: bottom;
  animation: squish-icon 0.75s infinite linear;
}
@keyframes squish-icon {
  0% {
    transform: scaleY(1);
  }
  30% {
    transform: scaleY(0.5);
  }
  70% {
    transform: scaleY(1.25);
  }
  100% {
    transform: scaleY(1);
  }
}
#resume a.page__title {
  display: block;
}
#resume a:has(.resume__block--heading) {
  transform: none;
}
#resume .resume__block--heading {
  transform: none;
}
#resume .resume__block--heading:hover {
  transform: none;
}
#resume .resume__block--heading section {
  margin: 0;
}
#resume .resume__block--bodies-of-text {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "resume-left resume-right";
  grid-column-gap: 2em;
}
#resume .resume__block--bodies-of-text section header {
  position: relative;
  margin-bottom: 1em;
}
#resume .resume__block--bodies-of-text section header:after {
  content: "";
  position: absolute;
  bottom: 0.25em;
  width: 100%;
  height: 1px;
}
#resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text {
  grid-area: resume-right;
  overflow-wrap: anywhere;
  padding-top: 0;
}
#resume .resume__block--bodies-of-text .resume__block--large-bodies-of-text {
  grid-area: resume-left;
  padding-top: 0;
}
#resume .resume__skills {
  margin-top: 3em;
}
#resume .resume__skills .resume__skills--header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
#resume .resume__skills .resume__skills--body .resume__skills--disclaimer {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 0.8em;
  position: relative;
  top: -1.5em;
}
#resume .resume__skills .resume__skills--body .resume__skills--disclaimer a {
  font-weight: 600;
}
#resume .resume__skills .resume__skills--body .resume__skills--skill:not(:first-of-type):not(:last-of-type) {
  margin: 0.5em 0;
}
#resume .resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container {
  position: relative;
  display: block;
  height: 1.8em;
  border: 0.25em solid;
  border-radius: 0.8em;
  width: 100%;
  overflow: hidden;
}
#resume .resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .progress-bar {
  position: relative;
  height: 1.3em;
  border-radius: 0.65em;
}
#resume .resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .skill-level__text {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  top: 0;
  left: 0.5em;
  width: calc(100% - 1em);
}
#resume .resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .skill-level__text .skill-level__text--description {
  line-height: 1em !important;
}
#resume .resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .skill-level__text .skill-level__text--value {
  line-height: 1em !important;
  font-weight: 600;
}
#resume .card {
  padding: 0.25em 0.5em;
  transition: all 0.25s ease-in-out;
}
#resume .card:first-of-type {
  margin-top: -0.25em;
}
#resume .card .card__main-content {
  position: relative;
  transition: all 0.75s ease-in-out;
  padding-bottom: 0;
}
#resume .card .card__collapsing-content {
  position: relative;
  transform-origin: top;
  transition: all 0.75s ease-in-out;
  padding-top: 0;
  transform: scaleY(0);
  height: 0;
}
#resume .card > .card__collapsing-content:before {
  height: 0;
}
#resume .card.card__expandable {
  border-radius: 0.25em;
}
#resume .card.card__expandable .card__title {
  padding-right: 1em;
}
#resume .card.card__expandable:hover, #resume .card.card__expandable:active {
  cursor: pointer;
}
#resume .card.card__expandable.collapsed > .card__main-content:before {
  content: "+";
  position: absolute;
  box-sizing: content-box;
  font-size: 1.5em;
  width: 1em;
  height: 1em;
  font-weight: 600;
  padding: 0;
  line-height: 1em;
  top: -0.1em;
  right: -0.4em;
  color: transparent;
  background-clip: text;
  animation: expanding-card-pulse 3s infinite ease-in-out;
}
#resume .card.card__expandable.collapsed > .card__collapsing-content {
  transform: scaleY(0);
  height: 0;
}
#resume .card.card__expandable:not(.collapsed) {
  margin-bottom: 0.5em;
}
#resume .card.card__expandable:not(.collapsed) > .card__main-content {
  padding-bottom: 0.6em;
}
#resume .card.card__expandable:not(.collapsed) > .card__collapsing-content {
  padding-top: 0.6em;
  transform: scaleY(1);
  height: 100%;
}
#resume .card.card__expandable:not(.collapsed) > .card__collapsing-content:before {
  content: "";
  position: absolute;
  top: -0.1em;
  left: 12.5%;
  width: 75%;
  height: 0.2em;
  border: 0 solid transparent;
  border-radius: 50%;
}
#resume .card.card__expandable:not(.collapsed) > .card__collapsing-content .card:not(.collapsed) > .card__collapsing-content:before {
  height: 0.1em;
  top: 0;
}
#resume .card:hover, #resume .card:active {
  cursor: auto;
}
#resume .card .card__technologies {
  font-style: italic;
}
#resume .card .card__technologies:not(.resume__education--isu--technologies) {
  font-weight: 500;
}
#resume .card .card .card__title {
  font-weight: 525;
}
#resume .card .card .card__expanded--element-list-heading,
#resume .card .card .card__expanded--bullet-point-heading {
  font-weight: 475;
}
#resume .card .card .card__expanded--title,
#resume .card .card .card__expanded--description.resume__education--course:first-of-type {
  font-weight: 500;
}
#resume .card .card__title.resume__education--course--title .course__id {
  width: 5em;
}
#resume .card .card__title.resume__education--course--title .course__title {
  padding-left: 1em;
}
#resume .card .card__description {
  margin-left: 1em;
  display: block;
  padding-bottom: 0;
}
#resume .card .card__expanded--description.resume__education--course {
  padding-bottom: 0;
}
#resume .card .card__expanded--description.resume__education--course:not(:first-of-type) {
  margin-left: 1em;
}
#resume .card .card__expanded--description + .card__expanded--bullet-point-heading {
  padding-top: 0.25em;
}
#resume .card .card__expanded--title {
  padding-top: 0;
  padding-bottom: 0.25em;
}
#resume .card .card__expanded--element-list-heading {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
#resume .card .card__expanded--element-list {
  padding: 0;
}
@media screen and (prefers-color-scheme: light), screen and (prefers-color-scheme: no-preference) {
  #resume *:not(h3, h5, h6) {
    color: black;
  }
  #resume .page__title:link, #resume .page__title:visited {
    text-decoration: none;
    color: var(--color-title-light);
  }
  #resume h1,
#resume h2 {
    color: var(--color-title-light);
  }
  #resume .resume__block--bodies-of-text section header:after {
    background-color: var(--color-heading-light);
  }
  #resume .card .card__technologies:not(.resume__education--isu--technologies) {
    color: var(--color-heading-light);
  }
  #resume .card.card__expandable:hover.collapsed, #resume .card.card__expandable:active.collapsed {
    box-shadow: 0 0 1em 0.2em var(--color-background-title-light);
  }
  #resume .card.card__expandable .card__collapsing-content:before {
    background-color: var(--color-background-title-light);
  }
  #resume .card.card__expandable:not(.collapsed) {
    box-shadow: 0.2em 0.2em 0.5em -0.1em var(--color-background-title-light);
  }
  #resume .card.card__expandable .card.card__expandable:not(.collapsed) {
    box-shadow: 0.2em 0.2em 0.5em 0.15em var(--color-background-title-light);
  }
  @keyframes expanding-card-pulse {
    0% {
      background-color: rgba(var(--secondary-color-rgb), 0.1);
    }
    50% {
      background-color: rgba(var(--secondary-color-dark-rgb), 1);
    }
    100% {
      background-color: rgba(var(--secondary-color-rgb), 0.1);
    }
  }
  #resume .resume__contact svg {
    fill: var(--color-link-light);
  }
  #resume .resume__skills--body .resume__skills--disclaimer a:link:hover, #resume .resume__skills--body .resume__skills--disclaimer a:visited:hover {
    color: var(--color-link-hover-light);
  }
  #resume .progress-bar__container {
    background-color: var(--color-background-accent-light);
    border-color: var(--color-background-accent-light) !important;
  }
  #resume .progress-bar__container .progress-bar {
    background-color: var(--color-foreground-accent-light);
  }
  #resume .progress-bar__container .skill-level__text .skill-level__text--description,
#resume .progress-bar__container .skill-level__text .skill-level__text--value {
    color: var(--color-text-accent-light);
  }
}
@media screen and (prefers-color-scheme: dark) {
  #resume *:not(h3, h5, h6) {
    color: var(--secondary-color-dark);
  }
  #resume h1,
#resume h2 {
    color: var(--color-title-dark);
  }
  #resume .page__title:link, #resume .page__title:visited {
    text-decoration: none;
    color: var(--color-title-dark);
  }
  #resume .resume__block--bodies-of-text section header:after {
    background-color: var(--color-heading-dark);
  }
  #resume .card .card__technologies:not(.resume__education--isu--technologies) {
    color: var(--color-heading-dark);
  }
  #resume .card.card__expandable:hover.collapsed, #resume .card.card__expandable:active.collapsed {
    box-shadow: 0 0 1em 0.2em var(--color-foreground-accent-dark);
  }
  #resume .card.card__expandable .card__collapsing-content:before {
    background-color: var(--color-foreground-accent-dark);
  }
  #resume .card.card__expandable:not(.collapsed) {
    box-shadow: 0.2em 0.2em 0.5em -0.1em var(--color-foreground-accent-dark);
  }
  #resume .card.card__expandable .card.card__expandable:not(.collapsed) {
    box-shadow: 0.2em 0.2em 0.5em 0.15em var(--color-foreground-accent-dark);
  }
  @keyframes expanding-card-pulse {
    0% {
      background-color: rgba(var(--secondary-color-rgb), 0.1);
    }
    50% {
      background-color: rgba(var(--secondary-color-lighter-rgb), 1);
    }
    100% {
      background-color: rgba(var(--secondary-color-rgb), 0.1);
    }
  }
  #resume .resume__contact svg {
    fill: var(--color-link-dark);
  }
  #resume .resume__skills--body .resume__skills--disclaimer a:link:hover, #resume .resume__skills--body .resume__skills--disclaimer a:visited:hover {
    color: var(--color-link-hover-dark);
  }
  #resume .progress-bar__container {
    background-color: var(--color-background-accent-dark);
    border-color: var(--color-background-accent-dark) !important;
  }
  #resume .progress-bar__container .progress-bar {
    background-color: var(--color-foreground-accent-dark);
  }
  #resume .progress-bar__container .skill-level__text .skill-level__text--description,
#resume .progress-bar__container .skill-level__text .skill-level__text--value {
    color: var(--color-text-accent-dark);
  }
}
@media print {
  #resume {
    padding-bottom: 0;
    height: 100%;
    background-color: white;
    font-size: 0.8rem;
  }
  #resume .resume__skills--skill.software,
#resume .resume__education--completed-courses,
#resume .card__collapsing-content,
#resume .expand-card-icon,
#resume .card.card__expandable.collapsed .card__main-content:before,
#resume .tooltip__icon,
#resume .resume__profile header,
#resume svg {
    /* Don't display the less relevant stuff when printing resume */
    display: none !important;
  }
  #resume * {
    line-height: 1em !important;
  }
  #resume *:not(h3, h5, h6) {
    color: black;
  }
  #resume h1,
#resume h2 {
    color: var(--color-title-light);
  }
  #resume h3,
#resume h5,
#resume h6 {
    color: var(--color-heading-light);
  }
  #resume .page__title {
    position: relative;
    overflow: hidden;
    background: none;
    z-index: 0;
    font-size: 1.2em;
    /* Enforcing background colors when printing */
  }
  #resume .page__title:link, #resume .page__title:visited {
    text-decoration: none;
    color: var(--color-title-light);
  }
  #resume .page__title:after {
    content: "";
    position: absolute;
    height: 0;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    border-bottom: 10em solid var(--color-background-title-light) !important;
  }
  #resume .page__body {
    padding: 0;
  }
  #resume section:not(.resume__header) {
    margin: 0;
    padding: 0.25em 1em;
  }
  #resume section:not(.resume__header):first-of-type {
    padding-top: 0.5em;
  }
  #resume section:not(.resume__header):last-of-type {
    padding-bottom: 0;
  }
  #resume section:not(.resume__header) header {
    padding-bottom: 0.5em;
  }
  #resume section:not(.resume__header) .card__technologies:not(.resume__education--isu--technologies) {
    color: var(--color-heading-light) !important;
  }
  #resume .resume__contact .resume__contact--body address div[class*="--"]:not(:first-of-type) {
    padding-top: 0.5em;
  }
  #resume .card {
    box-shadow: none !important;
    margin-bottom: 0 !important;
    padding-top: 0.125em;
    padding-bottom: 0.125em;
  }
  #resume .card.card__expandable.collapsed .card__main-content:before {
    content: "";
  }
  #resume .card.card__expandable:not(.collapsed) .card__main-content {
    padding-bottom: 0;
  }
  #resume .resume__block--bodies-of-text {
    grid-column-gap: 0;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text {
    position: relative;
    overflow: hidden;
    background: none;
    z-index: 0;
    /*display: flex;
    flex-direction: column;
    justify-content: space-between;*/
    /* Enforcing background colors when printing */
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    border-right: 25em solid rgba(var(--secondary-color-light-rgb), 0.2);
  }
  #resume .resume__block--bodies-of-text section header {
    position: relative;
    padding-bottom: 0.25em;
    margin: 0;
    /* Enforcing background colors when printing */
  }
  #resume .resume__block--bodies-of-text section header:after {
    position: absolute;
    bottom: 0.45em;
    left: 0;
    border-bottom: 1px solid var(--color-heading-light) !important;
    background: none;
  }
  #resume section.resume__skills {
    margin-top: 2em;
    margin-bottom: 0;
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--skill:not(:first-of-type):not(:last-of-type) {
    margin: 0;
    padding: 0.25em 0;
  }
  #resume section.resume__skills .resume__skills--body {
    padding-bottom: 1em;
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--disclaimer {
    top: -0.5em;
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--disclaimer a {
    font-size: 1em;
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container {
    overflow: hidden;
    border: 0 solid transparent;
    background: none;
    /* Enforcing background colors when printing */
    /* Enforcing background colors when printing */
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 20em solid var(--color-background-accent-light);
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .progress-bar {
    left: 0.25em !important;
    top: 0.25em;
    background: none;
    overflow: hidden;
    /* Enforcing background colors when printing */
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .progress-bar:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 20em solid var(--color-foreground-accent-light);
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .skill-level__text {
    left: 0.6em;
    background: none;
    align-items: center;
  }
  #resume section.resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .skill-level__text .skill-level__text--description,
#resume section.resume__skills .resume__skills--body .resume__skills--skill .progress-bar__container .skill-level__text .skill-level__text--value {
    color: black;
  }
}
@media screen and (max-width: 800px) {
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__skills .skill-level__text > * {
    font-size: 0.875em;
  }
}
@media screen and (max-width: 700px) {
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__skills .skill-level__text > * {
    font-size: 0.75em;
  }
}
@media screen and (max-width: 600px) {
  #resume .resume__block--bodies-of-text {
    display: flex;
    flex-direction: column;
  }
  #resume .resume__block--bodies-of-text section {
    margin-bottom: 2em;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text {
    display: flex;
    flex-direction: column;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__contact {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__contact .resume__contact--body address {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1em;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__contact .resume__contact--body address > div[class*="--"]:not(:last-child) {
    padding-top: 0;
    padding-bottom: 0.25rem;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__contact .resume__contact--body address > * {
    align-self: flex-start;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__skills {
    margin: 0 0 1em 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__skills .resume__skills--header {
    margin: 0;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__skills .resume__skills--disclaimer {
    top: -0.5em;
  }
  #resume .resume__block--bodies-of-text .resume__block--small-bodies-of-text .resume__skills .skill-level__text > * {
    font-size: 1em;
  }
  #resume .resume__block--bodies-of-text .resume__block--large-bodies-of-text .resume__experience {
    margin-bottom: 0;
  }
}

/*# sourceMappingURL=resume.css.map */
