/*
  Need to make a hamburger menu at 560px
*/
#header {
  grid-area: header;
  font-family: "Baloo 2", cursive;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding: 0.5rem 1rem;
  }

  #logo {
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      background-color: transparent;
    }

    #logo__link {
      height: 100%;
      width: 100%;
    }

    #logo__text {
      font-size: 1.1rem;
      font-weight: 500;
      letter-spacing: 0.05rem;
      animation: logo 20s 3 linear;
    }

    &:hover,
    &:active {
      #logo__text {
        animation: logo 4s infinite linear;
      }
    }

    @keyframes logo {
      0% {
      }
      7% {
        transform: rotateY(45deg) rotateZ(-7deg);
      }
      12% {
        transform: rotateY(90deg) rotateZ(-15deg);
      }
      17% {
        transform: rotateY(135deg) rotateZ(-23deg);
      }
      25% {
        transform: rotateY(180deg) rotateZ(-30deg);
      }
      33% {
        transform: rotateY(225deg) rotateZ(-23deg);
      }
      38% {
        transform: rotateY(270deg) rotateZ(-15deg);
      }
      43% {
        transform: rotateY(315deg) rotateZ(-7deg);
      }
      50% {
        transform: rotateY(360deg) rotateZ(0deg);
      }
      57% {
        transform: rotateY(405deg) rotateZ(7deg);
      }
      62% {
        transform: rotateY(450deg) rotateZ(15deg);
      }
      67% {
        transform: rotateY(495deg) rotateZ(23deg);
      }
      75% {
        transform: rotateY(540deg) rotateZ(30deg);
      }
      84% {
        transform: rotateY(585deg) rotateZ(23deg);
      }
      88% {
        transform: rotateY(630deg) rotateZ(15deg);
      }
      93% {
        transform: rotateY(675deg) rotateZ(7deg);
      }
      100% {
        transform: rotateY(720deg) rotateZ(0deg);
      }
    }
  }

  #nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .nav__item {
      font-weight: 500;
      position: relative;
      transition: transform 0.2s ease-in-out;

      &:not(.active-page) {
        &:hover,
        &:active {
          background-clip: text !important;
          color: transparent;
          animation: gradientLink 10s infinite linear;
          transform: scale(1.3);
          transition: transform 0.2s ease-in-out;
        }
      }

      &.active-page {
        &::before,
        &::after {
          background-color: inherit;
          scale: 1 4;
          transform: scale(1, 0.25);
          transition: transform 0.5s linear, scale 0.25s linear 0.5s,
            background-color 0.5s linear 1s;
        }
      }

      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 2rem;
        border: 0 solid transparent;
        border-radius: 5px;
        left: 0;
        top: 0.1rem;
        scale: 1 1;

        transform: scale(0);
        transform-origin: bottom right;
      }

      &::before {
        transform-origin: top left;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 94% 90%, 94% 10%, 6% 10%);
      }

      &::after {
        clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 94% 90%, 6% 90%, 6% 10%);
      }

      &.active-page {
        font-weight: 525;
      }
    }

    @keyframes gradientLink {
      0% {
        background-position-x: 0;
      }
      100% {
        background-position-x: 50em;
      }
    }
  }

  &::before,
  &::after {
    content: "";
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
  }

  /*

    *********************** COLOR SCHEMES ***********************

  */

  @media screen and (prefers-color-scheme: light),
    screen and (prefers-color-scheme: no-preference) {
    color: var(--secondary-color-dark);
    background-color: var(--secondary-color-lighter);

    a {
      color: var(--secondary-color-dark);
    }

    #nav .nav__item {
      color: var(--secondary-color-dark);

      &:before,
      &:after {
        background-color: var(--secondary-color-dark);
      }

      &.active-page {
        color: var(--secondary-color-darkest);
      }

      &:not(.active-page):hover,
      &:not(.active-page):active {
        background: linear-gradient(
          90deg,
          var(--primary-color-dark) 0%,
          var(--secondary-color-dark) 50%,
          var(--primary-color-dark) 100%
        );
      }
    }

    #logo a {
      color: var(--primary-color-dark);
    }
  }

  @media screen and (prefers-color-scheme: dark) {
    color: var(--secondary-color);
    background-color: black;

    a {
      color: var(--secondary-color);
    }

    #nav .nav__item {
      color: var(--secondary-color);

      &:before,
      &:after {
        background-color: var(--secondary-color-lighter);
      }

      &.active-page {
        color: var(--secondary-color-lighter);
      }

      &:not(.active-page):hover,
      &:not(.active-page):active {
        background: linear-gradient(
          90deg,
          var(--primary-color) 0%,
          var(--secondary-color) 50%,
          var(--primary-color) 100%
        );
      }
    }

    #logo a {
      color: var(--primary-color);
    }
  }

  /*

    *********************** PRINT ***********************

  */

  @media print {
    & {
      /* Don't display the header */
      display: none !important;
    }
  }
}
