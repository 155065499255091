#footer {
  grid-area: footer;
  padding: 0.5rem 1rem;

  a {
    transition: all 0.2s linear;
  }

  /*

  *********************** COLOR SCHEMES ***********************

 */

  @media screen and (prefers-color-scheme: light),
    screen and (prefers-color-scheme: no-preference) {
    background-color: var(--secondary-color-lighter);

    & {
      color: var(--secondary-color);
    }

    a {
      color: var(--secondary-color-dark);

      &:link,
      &:visited {
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }

  @media screen and (prefers-color-scheme: dark) {
    background-color: black; //var(--secondary-color-darkester);

    & {
      color: var(--secondary-color-darker);
    }

    a {
      color: var(--secondary-color-darker);

      &:link,
      &:visited {
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }

  /*

*********************** PRINT ***********************

*/

  @media print {
    display: none !important;
  }
}
