.tooltip {
  /*

  *********************** COLOR SCHEME ***********************

  */
}
.tooltip.tooltip__container {
  position: relative;
  align-self: center;
  justify-self: center;
  top: -0.25rem;
  height: 0;
}
.tooltip.tooltip__icon svg {
  margin-left: 0.5rem;
  top: 0.375rem;
  border: 0.0625rem solid;
  padding: 0.125rem;
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
}
.tooltip.tooltip__icon:hover + .tooltip__container .tooltip__text, .tooltip.tooltip__icon:active + .tooltip__container .tooltip__text {
  transform: scaleY(1);
}
.tooltip .tooltip__text {
  font-size: 0.75rem;
  line-height: 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
  text-align: center;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 0.25s linear;
}
@media (prefers-color-scheme: dark) {
  .tooltip .tooltip__text {
    background-color: rgba(var(--secondary-color-rgb), 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-weight: 600;
  }
}

/*# sourceMappingURL=tooltip.css.map */
