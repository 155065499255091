#footer {
  grid-area: footer;
  padding: 0.5rem 1rem;
  /*

   *********************** COLOR SCHEMES ***********************

  */
  /*

  *********************** PRINT ***********************

  */
}
#footer a {
  transition: all 0.2s linear;
}
@media screen and (prefers-color-scheme: light), screen and (prefers-color-scheme: no-preference) {
  #footer {
    background-color: var(--secondary-color-lighter);
  }
  #footer {
    color: var(--secondary-color);
  }
  #footer a {
    color: var(--secondary-color-dark);
  }
  #footer a:link:hover, #footer a:visited:hover {
    color: var(--primary-color);
  }
}
@media screen and (prefers-color-scheme: dark) {
  #footer {
    background-color: black;
  }
  #footer {
    color: var(--secondary-color-darker);
  }
  #footer a {
    color: var(--secondary-color-darker);
  }
  #footer a:link:hover, #footer a:visited:hover {
    color: var(--primary-color);
  }
}
@media print {
  #footer {
    display: none !important;
  }
}

/*# sourceMappingURL=footer.css.map */
