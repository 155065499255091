:root {
  --primary-color-lighter: #9cf;
  --primary-color-light: #7af;
  --primary-color: #58f;
  --primary-color-dark: #36f;
  --primary-color-darker: #108;

  --secondary-color-lightester-rgb: 238, 255, 255;
  --secondary-color-lightest-rgb: 221, 255, 238;
  --secondary-color-lighter-rgb: 187, 255, 221;
  --secondary-color-light-rgb: 153, 255, 170;
  --secondary-color-rgb: 102, 238, 136;
  --secondary-color-dark-rgb: 68, 204, 102;
  --secondary-color-darker-rgb: 17, 153, 51;
  --secondary-color-darkest-rgb: 17, 85, 34;
  --secondary-color-darkester-rgb: 0, 34, 17;

  --secondary-color-lightester: #eff;
  --secondary-color-lightest: #dfe;
  --secondary-color-lighter: #bfd;
  --secondary-color-light: #9fa;
  --secondary-color: #6e8;
  --secondary-color-dark: #5d7;
  --secondary-color-darker: #193;
  --secondary-color-darkest: #152;
  --secondary-color-darkester: #021;

  --color-background-title-light: var(--secondary-color);
  --color-background-light: var(--secondary-color-lightester);
  --color-title-light: white;
  --color-heading-light: var(--secondary-color);
  --color-link-light: var(--secondary-color-dark);
  --color-link-hover-light: var(--primary-color);
  --color-text-light: var(--secondary-color-darker);
  --color-background-accent-light: var(--secondary-color-lighter);
  --color-foreground-accent-light: var(--secondary-color);
  --color-text-accent-light: var(--secondary-color-darker);

  --color-background-title-dark: var(--secondary-color-darkester);
  --color-background-dark: var(--secondary-color-darkest);
  --color-title-dark: white;
  --color-heading-dark: var(--secondary-color-light);
  --color-link-dark: var(--secondary-color);
  --color-link-hover-dark: var(--primary-color);
  --color-text-dark: var(--secondary-color-dark);
  --color-background-accent-dark: var(--secondary-color-darker);
  --color-foreground-accent-dark: var(--secondary-color);
  --color-text-accent-dark: var(--secondary-color-darkester);

  --tertiary-color-lighter: #bbf;
  --tertiary-color-light: #86f;
  --tertiary-color: #71f;
  --tertiary-color-dark: #51c;
  --tertiary-color-darker: #317;

  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 16px;
  font-family: "Baloo 2", cursive;
  //font-family: "Indie Flower", cursive;
  //font-family: Calibri, "Lora", Ariel, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  line-height: 1.25em;
  box-sizing: border-box;
}

body {
  height: 100vh;
  /*
      Note: 100vw will break any page with a scrollbar (my resume page).
      viewport width includes the width of the scrollbar.
     */
  width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

h1,
h1 * {
  font-size: 2.1667em;
  font-weight: 700;
}

h2,
h2 * {
  font-size: 1.6667em;
  font-weight: 700;
}

h3,
h3 * {
  font-size: 1.3333em;
  font-weight: 700;
}

h4,
h4 * {
  font-weight: 650;
}

h5,
h5 * {
  font-weight: 600;
}

h6,
h6 * {
  font-weight: 550;
}

h4,
h4 *,
h5,
h5 *,
h6,
h6 *,
p,
p *,
span,
li,
a {
  font-size: 1em;
}

#root {
  display: grid;
  grid-template-columns: 1fr 10fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header header"
    "side-nav body"
    "footer footer";
  height: 100%;
  width: 100%;
}

#content {
  grid-area: body / footer / body;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  #home {
    position: relative;
    .page {
      padding-bottom: 0;
      top: 0;
      min-height: calc(100vh + 1.25em);

      &:not(.hero) {
      }

      &.hero {
        max-height: calc(100vh + 2.5em);
        .page__title {
          padding-bottom: 0;
        }

        .hero__image {
          height: calc(100vh + 2.5em);
        }
      }

      .page__title,
      &:before,
      &:after {
        position: sticky;
        top: 0;
      }
    }
  }
}

.content {
  /*
    
      Styling for all web pages. Easily overridden with page-id styling
      
    */

  .page {
    padding-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "page-title-cushion-left page-title page-title-cushion-right"
      ". page-body .";
    page: page;

    &:before,
    &:after {
      content: "";
      height: 100%;
      z-index: 1;
    }

    &:before {
      grid-area: page-title-cushion-left;
    }

    &:after {
      grid-area: page-title-cushion-right;
    }

    .page__title {
      grid-area: page-title;
      padding: 0.75em 1em;
      z-index: 1;
    }

    .page__body {
      grid-area: page-body;
      padding: 0 1rem 1rem 1rem;
      max-width: 75rem;
      z-index: 0;

      & > *:not(section) {
        padding-top: 1rem;
      }
    }

    a {
      display: inline-block;
      transition: all 0.2s linear;

      &:link,
      &:visited {
        &:hover {
          transform: scale(1.1);
        }
      }
    }

    section {
      margin: 1em 0;
    }

    p:has(+ *) {
      margin-bottom: 1em;
    }
  }
}

/*

  *********************** COLOR SCHEMES ***********************

   */

@media screen and (prefers-color-scheme: light),
  screen and (prefers-color-scheme: no-preference) {
  :root {
    color: var(--color-text-light);
    background-color: var(--color-background-light);
  }

  #content {
    #home {
      background-color: white;
    }
  }

  .page {
    background-color: var(--color-background-light);

    &:before,
    &:after,
    .page__title {
      background-color: var(--color-background-title-light);
    }

    .page__title {
      &,
      & * {
        color: var(--color-title-light);
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      & * {
        color: var(--color-heading-light);
      }
    }
  }

  a {
    color: var(--color-link-light);

    &:link,
    &:visited {
      &:hover {
        color: var(--color-link-hover-light);
      }
    }
  }
}

@media screen and (prefers-color-scheme: dark) {
  :root {
    color: var(--color-text-dark);
    background-color: var(--color-background-dark);
  }

  #content {
    #home {
      background-color: white;
    }
  }

  .page {
    background-color: var(--color-background-dark);

    &:before,
    &:after,
    .page__title {
      background-color: var(--color-background-title-dark);
    }

    .page__title {
      &,
      & * {
        color: var(--color-title-dark);
      }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      &,
      & * {
        color: var(--color-heading-dark);
      }
    }
  }

  a {
    color: var(--color-link-dark);

    &:link,
    &:visited {
      &:hover {
        color: var(--color-link-hover-dark);
      }
    }
  }
}

/*

  *********************** PRINT ***********************

   */

@media print {
  :root {
    font-size: 1.25em;
  }

  .hidden {
    display: none !important;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  #home {
    .page {
      min-height: 100vh !important;
    }
  }

  .content {
    background-color: white;

    .page {
      page: page;
      page-break-inside: avoid;

      background-color: white !important;
      position: relative !important;

      .page__title {
        padding: 0.5em 1em !important;
      }

      .page__body {
        padding: 0 1em;

        & > *:not(section) {
          padding-top: 1em;
        }
      }

      p:has(+ *) {
        margin-bottom: 0.5em;
      }
    }
  }
}

@page page {
  size: letter portrait;
  margin: 0.25in;
}
