#side-nav {
  grid-area: side-nav;
}
@media print {
  #side-nav {
    display: none !important;
  }
}

/*# sourceMappingURL=side-nav.css.map */
