#hero {
  width: 100%;
  height: 100%;
  padding-bottom: 0;
  font-size: 2em;

  .hero__image {
    grid-column: page-body;
    grid-row: page-title / page-body;
    display: block;
    max-width: 75rem;
    width: min(100vw, 75rem);
    height: calc(100vh - 1.75rem);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-origin: padding-box;
    background-attachment: fixed;
  }

  .hero__text {
    .hero__text--header {
      line-height: 1em;
    }
  }

  .page__title {
    padding-top: 0.75em;
    background: none;
  }

  &.page {
    &:before,
    &:after {
      background: none;
    }
  }

  /*

  *********************** COLOR SCHEMES ***********************

   */

  @media screen and (prefers-color-scheme: light),
    screen and (prefers-color-scheme: no-preference) {
    .hero__text {
      * {
        color: var(--color-title-light);
      }
    }
  }

  @media screen and (prefers-color-scheme: dark) {
    .hero__text {
      * {
        color: var(--color-title-dark);
      }
    }
  }

  /*

*********************** MEDIA QUERIES ***********************

*/

  @media screen and (max-width: 600px) {
    .hero__image {
      background-attachment: scroll;
      background-position: center bottom;
    }
  }

  @media screen and (max-width: 1360px) {
    font-size: calc(1vw + 1.1em);

    .hero__image {
      background-size: cover;
    }
  }

  @media screen and (min-width: 1361px) {
    .hero__image {
      background-attachment: fixed;
      background-position: center;
    }
  }
}

/*

  *********************** PRINT ***********************

   */

@media print {
  #home {
    .page {
      &#hero {
        .page__title {
          .hero__text {
            position: relative;
            top: 0;
            left: 0;

            * {
              text-align: center;
            }
          }
        }

        .page__body {
          &.hero__image {
            max-height: 100vh !important;
            background: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
